@import "swiper/scss";
@import "swiper/scss/effect-fade";

.slideshow{
  height: calc(100vh - 90px);
  padding-top: 90px;
}

.swiper--begin {
  @media (min-width: 768px) {
    mask-image: linear-gradient(to right, hsl(0 0% 0% / 1), hsl(0 0% 0% / 1) 20%, hsl(0 0% 0% / 1) 80%, hsl(0 0% 0% / 0));
  }
}

.swiper--end {
  @media (min-width: 768px) {
    mask-image: linear-gradient(to left, hsl(0 0% 0% / 1), hsl(0 0% 0% / 1) 20%, hsl(0 0% 0% / 1) 80%, hsl(0 0% 0% / 0));
  }
}

.news__wrapper{
  padding-bottom: 60px;

  @media (min-width: 960px) {
    padding: 0 30px 60px 30px;
  }
}

.swiper-pagination {
  position: absolute;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;

  &.swiper-pagination-hidden {
    opacity: 0;
  }

  .swiper-pagination-disabled > &,
  &.swiper-pagination-disabled {
    display: none !important;
  }
}

.swiper-pagination-bullets {
  bottom: 30px;
  display: flex;
  gap: 9px;
  left: 5%;
  width: 100%;
}

.swiper-pagination-bullet {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50%;
  background: var(--color-light-grey);

  @at-root button#{&} {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none;
  }

  .swiper-pagination-clickable & {
    cursor: pointer;
  }

  &:only-child {
    display: none !important;
  }
}

.swiper-pagination-bullet-active {
  background: var(--color-dark-pink);
}

.swiper__image{
  display: block;

  > img{
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &:before{
    background-image: linear-gradient(to top, var(--slider-color-1), var(--slider-color-2));
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  @media (min-width: 768px) {
    background-image: linear-gradient(to right, var(--slider-color-1), var(--slider-color-2));
  }
}

.swiper__content{
  color: var(--color-white);
  left: 2%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @media (min-width: 768px) {
    left: 5%;
    width: 40%;
  }
}

.swiper__text{
  margin-bottom: 30px;
  width: 70%;
}
