.step{
  padding-bottom: 60px;
  padding-top: 60px;

  @media (min-width: 960px) {
    display: flex;
  }
}

.step__text{
  p{
    margin-bottom: 21px;
  }

  @media (max-width: 959px) {
    margin-bottom: 30px;
  }

  @media (min-width: 960px) {
    width: calc(100% / 6);
  }
}

.step__wrapper{
  @media (min-width: 960px) {
    width: calc(100% - (100% / 6));
  }
}

.step__items{
  width: 100%;

  @media (min-width: 960px) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
}

.step__item{
  text-align: center;

  &:first-of-type{
    .step__timeline{
      &:before{
        left: 50%;
        width: 50%;
      }
    }
  }

  &:last-of-type{
    .step__timeline{
      &:before{
        width: 50%;
      }
    }
  }
}

.step__illustration{
  height: 240px;

  > img{
    height: 230px;
  }
}

.step__timeline{
  position: relative;

  &:before{
    content: '';
    border-bottom: 1px dotted var(--color-dark-grey);
    display: block;
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 100;
  }
}

.step__point{
  align-items: center;
  border: 1px solid transparent;
  border-radius: 50%;
  display: flex;
  height: 75px;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  width: 75px;
  z-index: 101;

  &:before{
    background-color: var(--color-light-grey);
    border-radius: 50%;
    content: '';
    display: block;
    height: 21px;
    width: 21px;
  }
}

.step__point--selected{
  background-color: var(--color-light-pink);
  border-color: var(--color-dark-pink);

  &:before{
    background-color: var(--color-dark-pink);
  }
}

.step__content{
  padding-top: 9px;
}

.step__title{
  color: var(--text-color);
  font-size: 1.333rem;
  font-weight: 600;
  margin-bottom: 9px;
}

.step__title--active{
  color: var(--color-pink);
}