@import "/node_modules/leaflet/dist/leaflet.css";

.locations{
  background-color: var(--color-cream);
  padding-bottom: 30px;
  padding-top: 30px;
  text-align: center;
}

.location__items{
  margin-top: 45px;

  @media (min-width: 768px) and (max-width: 959px) {
    display: grid;
    grid-column-gap: 21px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 960px) {
    display: grid;
    grid-column-gap: 21px;
    grid-template-columns: repeat(4, 1fr);
  }
}

.location__item{
  margin-bottom: 30px;
  position: relative;
  z-index: 101;
}

.location__region{
  height: 50px;

  @media (min-width: 768px) {
    height: 110px;
  }
}

.location__map{
  border-radius: 15px;
  height: 264px;
  width: 100%;
}

.location__balloon{
  padding-right: 40px;
  position: relative;

  &:after{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' fill='none'%3E%3Cpath fill='%23D25A8A' d='M21.703 3.111a2.89 2.89 0 0 0-3-.71l-14.15 4.73a2.9 2.9 0 0 0-.12 5.47l5.24 2a.929.929 0 0 1 .53.52l2 5.25a2.87 2.87 0 0 0 2.71 1.88h.07a2.88 2.88 0 0 0 2.69-2l4.73-14.17a2.89 2.89 0 0 0-.7-2.97Zm-1.15 2.34-4.78 14.18a.88.88 0 0 1-.84.62.92.92 0 0 1-.87-.58l-2-5.25a2.91 2.91 0 0 0-1.67-1.68l-5.25-2a.9.9 0 0 1-.59-.87.88.88 0 0 1 .62-.84l14.18-4.73a.91.91 0 0 1 1.2 1.15Z'/%3E%3C/svg%3E");
    content: '';
    display: block;
    height: 25px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
  }

  p{
    margin: 0;
  }

  a{
    color: var(--color-pink);
    text-decoration: none;
  }
}

.location__city{
  color: var(--color-black);
  font-size: 1.333rem;
  margin-top: 12px;
}

.location__contacts{
  align-content: center;
  display: flex;
  justify-content: center;
  gap: 9px;
  margin-top: 15px;
}

.location__contact{
  align-items: center;
  background-color: var(--color-light-pink);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 35px;
  width: 35px;
}