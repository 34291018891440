.news{
  padding-bottom: 60px;
  padding-top: 60px;

  @media (min-width: 960px) {
    display: flex;
  }
}

.news__text{
  p{
    margin-bottom: 21px;
  }

  @media (max-width: 959px) {
    margin-bottom: 30px;
  }

  @media (min-width: 960px) {
    width: calc(100% / 4);
  }
}

.news__items{
  position: relative;

  @media (min-width: 960px) {
    width: calc(100% - (100% / 4));
  }
}
