.news-article{
  background-color: var(--color-white);
  border-radius: 15px;
  box-shadow: 10px 10px 50px 3px var(--shadow);
}

.news-article--fixed{
  height: 475px;
  width: 360px;
}

.news-article__media{
  > img{
    border-radius: 15px 15px 0 0;
  }
}

.news-article__header{
  padding: 36px 18px;
}

.news-article__title{
  color: var(--color-dark-pink);
  font-size: 1.143rem;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 9px;
  margin-top: 9px;
}

.news-article__link{
  color: inherit;
  text-decoration: none;
}

.news-article__call{
  color: var(--color-pink);
  text-decoration: none;
}