.home-block{
  @media (min-width: 960px) {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
  }
}

.home-block__text{
  padding: 15px 2%;

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }

  @media (min-width: 768px) {
    display: flex;
    gap: 30px;
  }
}

.home-block__illustration{
  text-align: center;
  > img{
    height: auto;
  }
}

.home-block__info{
  p{
    margin-bottom: 15px;
  }

  @media (min-width: 960px) {
    width: 50%;
  }
}

.home-block__title{
  color: var(--color-dark-grey);
  font-size: 2.667rem;
}

.home-block__media{
  grid-column-start: 1;
  grid-row-start: 1;
}