.counters{
  background-color: var(--color-cream);
  padding-bottom: 45px;
  padding-top: 45px;
  text-align: center;
}

.counter__items{
  display: grid;
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 45px;

  @media (min-width: 960px) {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: repeat(4, 1fr);
  }
}

.counter__item{
  color: var(--color-dark-grey);
}

.counter__value{
  color: var(--color-dark-pink);
  display: block;
  font-size: 3.3rem;
  font-weight: 800;

  @media (min-width: 960px) {
    font-size: 5rem;
  }
}

.counter__label{
  font-size: 0.9rem;

  @media (min-width: 960px) {
    font-size: 1.1rem;
  }
}